<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-checkbox
          checked="true"
          @change="() => (this.filtersHidden = !this.filtersHidden)"
          ><b>show filters</b></b-form-checkbox
        >
      </b-col>
    </b-row>
    <!-- <b-row align-h="end" class="mb-1 pr-1">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-primary"
        size="sm"
        class=""
        @click="() => (this.filtersHidden = !this.filtersHidden)"
      >
        {{ filtersHidden == true ? "Show Filters" : "Hide Filters" }}
      </b-button>
    </b-row> -->
    <b-card v-if="filtersHidden == false">
      <!-- <b-card-header class="pb-50">
      <h5>Filters</h5>
    </b-card-header> -->

      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2" v-if="!hideMovie">
          <b-form-group>
            <v-select
              id="movie"
              class="w-100"
              placeholder="Select Movie"
              v-model="form.movie"
              label="name"
              :reduce="(option) => option.id"
              :options="$store.state.master.movies"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              @search="onSearch"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2" v-if="!hideVendor">
          <b-form-group>
            <v-select
              id="vendor"
              class="w-100"
              placeholder="Select Licensor/Lisensee"
              v-model="form.vendor"
              label="company"
              :options="$store.state.master.vendors"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12" md="3" class="mb-md-0 mb-2" v-if="!hideTerritory">
          <b-form-group>
            <v-select
              id="territory"
              class="w-100"
              placeholder="Select Territory"
              v-model="form.territory"
              label="lbl_name"
              :options="$store.state.master.territory"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </b-form-group>
        </b-col> -->
        <b-col cols="12" md="3" class="mb-md-0 mb-2" v-if="!hideRight">
          <b-form-group>
            <v-select
              id="right"
              class="w-100"
              placeholder="Select Right"
              v-model="form.right"
              label="lbl_name"
              :options="$store.state.master.rights"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2" v-if="!hideLanguage">
          <b-form-group>
            <v-select
              id="language"
              class="w-100"
              placeholder="Select Language"
              v-model="form.language"
              label="lbl_name"
              :options="$store.state.master.language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2" v-if="!hideStatus">
          <b-form-group>
            <v-select
              id="status"
              class="w-100"
              placeholder="Select Status"
              v-model="form.status"
              :options="statusOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2" v-if="!hideDateRange">
          <b-form-group>
            <flat-pickr
              id="date_range"
              v-model="form.date_range"
              :config="{ ...$flatPickrConfig, mode: 'range' }"
              class="form-control"
              placeholder="Select Date Range"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-info"
            class="mr-1"
            @click.prevent="submitForm"
          >
            Search
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="resetForm"
          >
            Reset
          </b-button>
        </b-col>
        <b-col>
          <b-button
            size="sm"
            @click="downloadCSV()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="outline-success"
            class="mr-1 mb-1"
          >
            Export CSV
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";

import { labels } from "@/utils/constants";

export default {
  components: {
    BForm,
    BFormGroup,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
    BFormCheckbox,
  },
  props: {
    hideMovie: Boolean,
    hideVendor: Boolean,
    hideTerritory: Boolean,
    hideRight: Boolean,
    hideLanguage: Boolean,
    hideStatus: Boolean,
    hideDateRange: Boolean,
    showFilters: { type: Boolean, default: true },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filtersHidden: true,
      statusOptions: [
        { value: "0", label: "Active" },
        { value: "1", label: "Inactive" },
        { value: "2", label: "All" },
      ],

      form: {
        movie: null,
        vendor: null,
        territory: null,
        right: null,
        language: null,
        status: null,
        date_range: null,
      },
      searchTerm: "",
    };
  },

  methods: {
    submitForm() {
      //console.log(this.form, "form");

      this.$emit("submitFilter", this.form);
    },
    downloadCSV() {
      this.$emit("downloadCSV");
    },
    resetForm() {
      this.$emit("resetFilter");
      this.form = {
        movie: null,
        vendor: null,
        territory: null,
        right: null,
        language: null,
        status: null,
        date_range: null,
      };
    },
    onSearch(searchTerm) {
      this.searchTerm = searchTerm;
      this.$store.dispatch("master/setMoviesMaster", { search: searchTerm });
    },
  },

  beforeMount() {
    this.$store.dispatch("master/setVendorsMaster");
    this.$store.dispatch("master/setMoviesMaster");
    if (!this.$store.state.master.territory.length) {
      this.$store.dispatch("master/setLabelMaster", { key: labels.territory });
    }
    if (!this.$store.state.master.rights.length) {
      this.$store.dispatch("master/setLabelMaster", { key: labels.rights });
    }
    if (!this.$store.state.master.language.length) {
      this.$store.dispatch("master/setLabelMaster", { key: labels.language });
    }

    this.filtersHidden = this.showFilters == true ? false : true;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
